import { quoteApplicantName } from "./quoteApplicantName";
import { FormBlock } from "@/components/FormBuilder/types";
import {
  useFormSection,
  useField,
  useFormFieldGroup,
  useSelectField
} from "@/components/FormBuilder/Helpers";
import * as selectOptions from "@/helpers/selectOptions";
import { subYears } from "date-fns";

const maritalStatusOptions = selectOptions.maritalStatusOptions.filter(
  option => option.value !== "divorced"
);

export const quoteApplicantForm = (
  applicantIsMarried: boolean = false,
  isCoApplicant: boolean = false
): FormBlock[] => {
  const quoteApplicantForm = [
    ...quoteApplicantName("applicant", { applicantIsMarried, isCoApplicant }),
    ...useFormFieldGroup([...applicantMoreDetails(isCoApplicant)], {
      layout: isCoApplicant ? "2-col" : "3-col"
    })
  ];
  return quoteApplicantForm;
};

export const quoteApplicationClientForm = (
  policyType: string,
  disabled?: boolean
): FormBlock[] => {
  const addressLabel =
    policyType === "D" ? "Applicant's current address" : "Previous Address";
  const form = [
    ...useFormSection(quoteApplicantForm(), {
      title: "Applicant Information"
    }),
    useField(
      {
        key: "riskAddress",
        label: "Risk Address",
        required: true,
        type: "address_viewer"
      },
      ["mb-4"]
    )
  ];

  if (policyType === "D") {
    form.push(
      useField({
        key: "previousAddress",
        label: addressLabel,
        required: true,
        type: disabled ? "address_viewer" : "address",
        addressType: "physicalAddress",
        validateAddress: false,
        useFormChangedVisibly: true,
        limitToTexas: false
      })
    );
  } else {
    form.push(
      useField(
        {
          key: "movedInYears",
          preamble: "Have you moved in last 3 years?",
          required: true,
          type: "radio_button_group",
          options: selectOptions.yesNoOptions
        },
        ["mb-8"]
      )
    );

    form.push(
      useField({
        key: "previousAddress",
        label: addressLabel,
        required: true,
        type: disabled ? "address_viewer" : "address",
        addressType: "physicalAddress",
        validateAddress: false,
        useFormChangedVisibly: true,
        limitToTexas: false,
        conditions: {
          and: [
            {
              field: "movedInYears",
              value: "Yes",
              operator: "equals"
            }
          ]
        }
      })
    );
  }

  return form;
};

function applicantMoreDetails(isCoApplicant: boolean) {
  const applicantMoreDetails = [
    useField({
      key: "applicant.dateOfBirth",
      preamble: "DOB",
      required: true,
      allowFirstOption: true,
      type: "custom-date",
      label: "Date of Birth (MM|DD|YYYY)",
      minYear: new Date(subYears(new Date(), 100)),
      maxYear: new Date(subYears(new Date(), 17))
    })
  ];
  const applicantSSN = useField(
    {
      key: "applicant.ssn",
      label: "SSN",
      type: "taxId2",
      required: false,
      showMaskSelector: false
    },
    [isCoApplicant ? "-mr-4" : ""]
  );
  const applicantMaritalStatus = useSelectField({
    key: "applicant.maritalStatus",
    label: "Marital Status",
    required: true,
    options: maritalStatusOptions,
    filterable: true,
    allowFirstOption: true
  });

  applicantMoreDetails.push(applicantMaritalStatus, applicantSSN);

  return applicantMoreDetails;
}
