
import CustomAlert from "@/components/CustomAlert/CustomAlert.vue";
import Vue from "vue";
export default Vue.extend({
  components: { CustomAlert },
  name: "OldRatingCustomAlert",
  props: {
    url: {
      type: String,
      required: true
    }
  },
  methods: {
    goToPropertyRatingsPage() {
      this.$router.push(this.url);
    }
  }
});
