import Vue from "vue";
const { __getText } = Vue.prototype;
import {
  useFormFieldGroup,
  useTextField
} from "@/components/FormBuilder/Helpers";
const firstNameLimit = 15;
const lastNameLimit = 25;

interface IApplicantNameOptions {
  applicantIsMarried: boolean;
  isCoApplicant: boolean;
}

type applicantFieldPrefix = "applicant" | "quoteApplication.applicant";
const nameIsValid = (name: string) => {
  const regex = new RegExp(/^[a-zA-Z ,.'-_0-9]+$/i);
  return regex.test(name);
};
const applicantDefaultOptions = {
  applicantIsMarried: false,
  isCoApplicant: false
};

const nameIsValidOptions = (nameType: string) => {
  return {
    method: "custom",
    customValidator: (value: any) => {
      if (value && value.length && !nameIsValid(value)) return false;
      return true;
    },
    error: `${nameType} cannot contain any special characters`
  };
};
export const quoteApplicantName = (
  prefix: applicantFieldPrefix = "applicant",
  options: IApplicantNameOptions = applicantDefaultOptions
) => {
  const nameIsRequired = options.isCoApplicant && options.applicantIsMarried;

  return [
    ...useFormFieldGroup(
      [
        useTextField({
          key: `${prefix}.firstName`,
          label: __getText("fields", "firstNameLabel"),
          placeholder: __getText("fields", "firstNameLabel"),
          required: nameIsRequired,
          validations: [
            {
              method: "maxLength",
              value: firstNameLimit,
              error: `First Name Must Not Be More Than ${firstNameLimit} Characters`
            },
            nameIsValidOptions("First Name")
          ]
        }),
        useTextField({
          key: `${prefix}.middleName`,
          label: "Middle Name",
          placeholder: "Middle Name",
          required: false,
          validations: [nameIsValidOptions("Middle Name")]
        }),
        useTextField({
          key: `${prefix}.lastName`,
          label: __getText("fields", "lastNameLabel"),
          placeholder: __getText("fields", "lastNameLabel"),
          required: nameIsRequired,
          validations: [
            {
              method: "maxLength",
              value: lastNameLimit,
              error: `Last Name Must Not Be More Than ${lastNameLimit} Characters`
            },
            nameIsValidOptions("Last Name")
          ]
        })
      ],
      { layout: "3-col" }
    )
  ];
};
