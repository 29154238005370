import { IQuoteApplicant } from "@/store/modules/quote/types";
import { object } from "dot-object";
import { omit } from "lodash";

type CoApplicantWithId = IQuoteApplicant & { _id: string; id: string };

export function generateEditCoApplicantPayload(
  {
    index,
    coApplicant
  }: {
    index: number;
    coApplicant: Partial<CoApplicantWithId>;
  },
  existingCoApplicants: CoApplicantWithId[]
): { coApplicants: Partial<IQuoteApplicant>; _id: string } {
  const formattedApplicantData: any = object(coApplicant);
  const existingCoApplicant = existingCoApplicants[index];
  let updatedFields: Partial<CoApplicantWithId> = {
    ...existingCoApplicant,
    ...formattedApplicantData.applicant
  };
  if (updatedFields.ssn && updatedFields.ssn.includes("xx")) {
    updatedFields = omit(updatedFields, ["ssn"]);
  }
  const payload = {
    coApplicants: updatedFields,
    _id: existingCoApplicant._id
  };
  return payload;
}
