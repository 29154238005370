
/** @delete-co-applicant
 * This was intended to manage an editing direct from the store
 * But we've not yet decided on what the editing will be - (
 * it could be a new quote or some quote-applicant thingy.
 * )
 *
 * So we just create this generic interface
 */
import { quoteApplicantForm } from "@/forms/shared/quote.client_information";
import FormBuilderDataCollector from "@/components/FormBuilder/FormBuilderDataCollector.vue";
import ApplicantViewer from "@/views/shared/quotes/RatingApplication/ApplicantsViewer.vue";

import Vue from "vue";
export default Vue.extend({
  name: "co-applicants-editor",
  components: {
    FormBuilderDataCollector,
    ApplicantViewer
  },
  props: {
    coApplicants: {
      type: Array,
      required: true,
      default: () => []
    },
    isRequired: {
      default: false,
      type: Boolean
    },
    applicantIsMarried: {
      default: false,
      type: Boolean
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    allowEditAndDelete: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      showApplicantForm: false,
      quoteApplicantForm: [],
      isCoApplicant: true,
      editingApplicantIndex: null as any
    };
  },
  mounted() {
    (this.quoteApplicantForm as any) = quoteApplicantForm(
      this.applicantIsMarried,
      this.isCoApplicant
    );
  },
  methods: {
    addCoApplicantHandler({ applicant }: { applicant: any }) {
      this.$emit("add-co-applicant", applicant);
      this.showApplicantForm = false;
    },
    editCoApplicantHandler(coApplicant: any) {
      this.$emit("edit-co-applicant", {
        index: this.editingApplicantIndex,
        coApplicant
      });
      this.editingApplicantIndex = null;
      this.showApplicantForm = false;
    },
    editCoApplicant(index: number): void {
      this.editingApplicantIndex = index; //;
      this.showApplicantForm = true;
    }
  },
  watch: {
    isRequired: {
      handler(val) {
        this.showApplicantForm = val;
      },
      immediate: true
    },
    applicantIsMarried: {
      handler(val) {
        (this.quoteApplicantForm as any) = quoteApplicantForm(
          val,
          this.isCoApplicant
        );
      }
    }
  },
  computed: {
    editing(): null | Object {
      if (this.editingApplicantIndex === null) return null;
      return this.coApplicants[this.editingApplicantIndex] as any;
    }
  }
});
