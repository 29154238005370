
import { quoteMapState } from "@/store/modules/quote";
import { userMapActions } from "@/store/modules/user";
import { get } from "lodash";
import Vue from "vue";
import { Session } from "@/helpers/processSession";

const session = new Session();
const EXPANDED_STATE_NAME = "AGA-underwriter-info-expanded";

export default Vue.extend({
  name: "underwriter-info",
  data() {
    return {
      underwriter: {},
      expanded: session.get(EXPANDED_STATE_NAME) ?? true,
      loading: false,
      agencyCode: ""
    };
  },
  mounted() {
    if (this.loading !== true) {
      this.loading = true;
      this.underwriter = this.editing?.quoteUnderwriter;
      this.agencyCode = get(this.editing, "agencyData.agencyCode", "");
    }
  },
  computed: {
    ...quoteMapState(["editing"]),
    fullName(): string {
      const firstName = get(this.underwriter, "firstName", "");
      const lastName = get(this.underwriter, "lastName", "");
      return `${firstName} ${lastName}`;
    },
    phoneNumber(): string {
      return get(this.underwriter, "phone.primary", "N/A");
    },
    email(): string {
      return get(this.underwriter, "email", "");
    },
    approvalCode(): string {
      return get(this.editing, "approvalCode", "");
    }
  },
  methods: {
    ...userMapActions(["getUnderWriters"]),
    toggleExpansion() {
      this.expanded = !this.expanded;
      session.set(EXPANDED_STATE_NAME, this.expanded);
    }
  }
});
