
import { get } from "lodash";
import Vue from "vue";
import DataView from "@/components/DataView/DataView.vue";
import { LEGACY_PHP_API_DATE_FORMAT } from "@/helpers/constants";
import { format } from "date-fns";

export default Vue.extend({
  name: "applicant-viewer",
  components: {
    DataView
  },
  props: {
    applicant: {
      type: Object,
      required: false,
      default: () => ({})
    },
    allowEdit: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    deleteHandler() {
      this.$emit("delete-co-applicant");
    },
    editHandler() {
      this.$emit("edit-co-applicant");
    }
  },
  computed: {
    getDateOfBirth(): string {
      const dob = get(this.applicant, "dateOfBirth", "") || "";
      if (dob) {
        return format(new Date(dob), LEGACY_PHP_API_DATE_FORMAT);
      }
      return "";
    },
    dataViewSchema(): any {
      return {
        attachments: [],
        sections: [
          {
            sectionTitle: "",
            showSection: true,
            showDivider: true,
            sectionColumnSize: "4",
            information: [
              {
                informationColumnSize: "1",
                key: "First Name",
                value: get(this.applicant, "firstName")
              },
              {
                informationColumnSize: "1",
                key: "Middle Name",
                value: get(this.applicant, "middleName")
              },
              {
                informationColumnSize: "1",
                key: "Last Name",
                value: get(this.applicant, "lastName")
              },
              {
                informationColumnSize: "1",
                key: "Email",
                value: get(this.applicant, "email")
              },
              {
                informationColumnSize: "1",
                key: "SSN",
                value: get(this.applicant, "ssn")
              },
              {
                informationColumnSize: "1",
                key: "Date of Birth",
                value: this.getDateOfBirth
              },
              {
                informationColumnSize: "1",
                key: "Marital Status",
                value: get(this.applicant, "maritalStatus")
              }
              // ,
              // {
              //   informationColumnSize: "1",
              //   key: "Employer Name",
              //   value: get(this.applicant, "employerName")
              // },
              // {
              //   key: "Employer Address",
              //   informationColumnSize: "1",
              //   value: get(this.applicant, "employerAddress")
              // }
            ]
          }
        ]
      };
    }
  }
});
